import { useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { FormActionTypes, FormSaveState } from '../context/form-reducer';
import { useFormContext } from './useFormContext';
import formSanitizer from '../utils/formSanitizer';
import { logWebsocketDisconnect } from '../services/session.service';
import { useLocalizer } from './useLocalizer';

const sendFieldCache = async (connection: signalR.HubConnection, currentFieldName: string | null) => {
  const fieldCacheString = localStorage.getItem('fieldCache');
  if (fieldCacheString !== null) {
    const fieldCache = JSON.parse(fieldCacheString);
    try {
      await Promise.all(
        Object.keys(fieldCache)
          .filter((key) => key !== currentFieldName)
          .map((key) => connection!.invoke('FieldChange', fieldCache[key].pageIndex, key, fieldCache[key].value))
      );
      localStorage.removeItem('fieldCache');
    } catch {}
  }
};

const cacheField = async (pageIndex: number, fieldName: string, value: any) => {
  const fieldCacheString = localStorage.getItem('fieldCache');
  const fieldCache = fieldCacheString ? JSON.parse(fieldCacheString) : {};
  var updatedFieldCache = {
    ...fieldCache,
    [fieldName]: { pageIndex, value },
  };
  localStorage.setItem('fieldCache', JSON.stringify(updatedFieldCache));
};

const useFormsHubClient = (formId: string, queryString: string) => {
  const { dispatch } = useFormContext();

  const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

  const client = {
    initiateForm: () => {
      return connection!.send('InitiateForm');
    },
    createForm: () => {
      return connection!.invoke('createForm');
    },
    saveForm: (validate: boolean) => {
      dispatch({ type: FormActionTypes.SetSaveState, payload: FormSaveState.Saving });
      return connection!.invoke('SaveForm', validate);
    },
    fieldChange: async (pageIndex: number, fieldName: string, value: any) => {
      try {
        await connection!.invoke('FieldChange', pageIndex, fieldName, value);
        await sendFieldCache(connection!, fieldName);
      } catch {
        cacheField(pageIndex, fieldName, value);
        //dispatch({ type: FormActionTypes.SetError, payload: l('connectionLostError') }); Removing error message temporarily as we need to do reconnect
        await logWebsocketDisconnect(formId || '');
      }
    },
    fileChange: (fieldName: string) => {
      return connection!.invoke('FileChange', fieldName);
    },
    validateForm: () => {
      return connection!.send('ValidateForm');
    },
    validatePages: (pageIndecies: number[]) => {
      return connection!.send('ValidatePages', pageIndecies);
    },
    resetForm: () => {
      return connection!.send('ResetForm');
    },
    submitForm: () => {
      return connection!.invoke('SubmitForm');
    },
    sendTimestampLogs: (logs: { eventType: string; timestamp: string }[] | null) => {
      return connection!.invoke('SendTimestampLogs', logs);
    },
  };

  useEffect(() => {
    changeConnectionUrl();
  }, [formId]);

  async function changeConnectionUrl() {
    if (connection !== null && connection.state === 'Connected') {
      await connection.stop();
    }
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`/hubs/form/${formId}${!!queryString ? `?${queryString}` : ''}`)
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }

  useEffect(() => {
    if (connection !== null) {
      connection
        .start()
        .then(() => {
          connection!.on('ReceiveForm', (form) => {
            dispatch({ type: FormActionTypes.SetForm, payload: formSanitizer(form) });
            dispatch({ type: FormActionTypes.SetSaveState, payload: FormSaveState.Saved });

            sendFieldCache(connection, null);
          });

          connection!.on('ReceiveValidation', (validation, validationType) => {
            dispatch({ type: FormActionTypes.SetFormValidation, payload: { validation: validation, validationType: validationType } });
          });

          connection!.on('ReceiveFormId', (formId) => {
            dispatch({ type: FormActionTypes.FormCreated, payload: formId });
          });
          client.initiateForm();
        })
        .catch((er) => console.error('Connection error', er));
    }

    return () => {
      connection?.stop();
    };
  }, [connection]);

  return client;
};

export { useFormsHubClient };
