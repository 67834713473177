import React, { FC } from 'react';
import MessageBubble, { MessageBubbleProps } from './message-bubble';
import MessageHeader from './message-header';
import { Origin } from '../../models/statuspage/origin';

const MessageThread: FC<MessageBubbleProps> = ({ message }) => {
  const flexDirection = message?.origin === Origin.Customer ? '--right' : '--left';

  return (
    <div className={`messages`}>
      <div className={`messages__wrapper messages__wrapper${flexDirection}`}>
        <MessageHeader message={message} />
        <MessageBubble message={message} display={flexDirection} />
      </div>
    </div>
  );
};

export default MessageThread;
